import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export function anchorLink(href) {
    return {
        href,
        onClick() {
            this.$store.scroll.gotoHref(href);
        },
    };
}
