import Alpine from "alpinejs";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const scrollDuration = 0.2;

function getTriggers() {
    return [...document.getElementsByClassName('contentPageTrigger')];
}
function getHeadings() {
    return [...document.getElementsByClassName('pageHeading')];
}
function getPages() {
    return [...document.getElementsByClassName('contentPage')];
}
function getAnchors() {
    return getPages().map(page => page.querySelector('.anchor'));
}
const getScrollTrigger = (trigger, i) => ({
    trigger,
    start: 'bottom top+=100px',
    end: '+=' + (window.innerHeight * 0.25),
    scrub: true,
    onToggle: trigger => {
        //console.log('trigger', trigger);
        if (trigger.isActive) return;
        const activeIndex = trigger.progress === 1 ? i + 1 : i;
        Alpine.store('scroll').setActive(activeIndex);
    },
});

export function initScroll() {
    const triggers = getTriggers();
    const headings = getHeadings();
    for (let i = 0; i < triggers.length; ++i) {
        const scrollTrigger = getScrollTrigger(triggers[i], i);
        gsap.timeline({scrollTrigger})
            .to(headings[i], {top: -70, opacity: 0});
        if (headings[i + 1]) {
            gsap.timeline({scrollTrigger})
                .from(headings[i + 1], {top: 100, opacity: 0});
        }
        headings[i].classList.remove('hidden');
    }
}

export function getIndexByHref(href) {
    return getAnchors().map(anchor => anchor.id).indexOf(href.substr(1));
}

export function scrollToHref(href) {
    if (window.history) {
        window.history.pushState(null, null, '/' + href)
    }
    gsap.to(window, {duration: scrollDuration, scrollTo: href});
}

export function scrollToIndex(i) {
    const anchors = getAnchors();
    scrollToHref(`#${anchors[i].id}`);
}



