export function gallery(imageCount) {
    return {
        activeIndex: 0,
        next() {
            this.activeIndex = (this.activeIndex + 1) % imageCount;
        },
        init() {
            setInterval(() => this.next(), 5000);
        },
    };
}
